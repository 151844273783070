:root {
  --clr-white: #f7f6f0;
  --clr-black: #000;
  --clr-grey: #343b3f;
  --clr-dark: #6f1132;
  --clr-medium: #daa79d;
  --clr-light: #f6bdac;
  --clr-accent: ##f4dba9;

  --home-mobile-h: 300px;
  --home-tablet-w: 80%;
  --home-dsktp-w: 49%;
  --home-dsktp-h: 320px;
  --home-large-dsktp-h: 400px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Playfair Display", serif;
  background-color: var(--clr-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  margin: auto;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.logo,
.static-logo {
  max-width: 70%;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}
.shrink-logo {
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  max-width: 40%;
}

@media (min-width: 768px) {
  .logo,
  .static-logo {
    max-width: 55%;
  }

  .shrink-logo {
    transition: all 0s ease-in-out;
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    max-width: 0%;
  }

  .scroll-nav {
    top: -40px;
  }
}

.static-nav {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.scroll-nav {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-image: url("./assets/Backgrounds/BG_top_long.svg");

  background-color: var(--clr-light);
  position: absolute;
  background-repeat: repeat-x;
  width: 100%;
  box-shadow: 7px 7px 15px 1px rgba(52, 59, 63, 0.72);
  z-index: 9999;
  position: fixed;
}

@media (min-width: 300px) {
  .scroll-nav {
    background-size: 650px;
    background-position: 0 -30px;
  }
}

@media (min-width: 375px) {
  .scroll-nav {
    background-size: 750px;
    background-position: 0 -40px;
  }
}
@media (min-width: 425px) {
  .scroll-nav {
    background-size: 850px;
    background-position: 0 -50px;
  }
}

@media (max-width: 768px) {
  .scroll-nav {
    background-size: 1200px;
    background-position: 0 -60px;
  }
}

.orange {
  color: orange;
  font-weight: bold;
  text-decoration: underline;
}

.redtext {
  background-color: red;
  color: white;
  font-size: 3rem;
}

.pinkstyle {
  color: pink;
  font-style: italic;
  font-size: 30px;
}

.sub-page-heading {
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 50px;
  font-weight: bold;
}

.link-light {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  color: var(--clr-white);
}

.link-red {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  color: var(--clr-dark);
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.selected {
  display: none;
}

.open {
  display: flex;
}
